<nav>
  <div class="footer-mobile">
      <table class="footer-mobile">
      <tr>
        <td class="footer-header-icon">
          <a routerLink="/home" id="logo"><img src="../../assets/images/logos/SDiOrb_newColors.svg"></a>
        </td>
        <td></td>
        <td class="footer-mobile-social">
          <a href="https://www.facebook.com/"><img class="footer-icon-hidden" src="../../assets/images/facebook.svg" /></a>&nbsp;
          <a href="https://www.instagram.com/"><img class="footer-icon-hidden" src="../../assets/images/instagram.svg" /></a>&nbsp;
          <a href="https://www.linkedin.com/company/software-decisions-inc."><img class="footer-icon-hidden" src="../../assets/images/linkedin.svg" /></a>
        </td>
      </tr>
      <tr>
        <td class="footer-mobile-copyright1">
          Copyright 2020. Software Decision, Inc. All Rights Reserved
        </td>
        <td></td>
        <td class="footer-mobile-copyright2">
          Legal and Private Policy
        </td>
      </tr>
      </table>
  </div>
  <div class="footer">
      <table class="footer-bg">
      <tr>
        <td class="footer-box-one">
          <table class="footer-inner-table-width">
            <tr>
              <td class="footer-header-icon">
                <a routerLink="/home" id="logo"><img src="../../assets/images/logos/SDiOrb_newColors.svg"></a>
              </td>
              <td class="footer-header-navigate-contact">
                 Navigate
              </td>
              <td class="footer-header-navigate-contact">
                Contact
              </td>
              <td class="footer-header-visitus">
                Visit Us
              </td>
            </tr>
            <tr>
              <td class="centered">
                <a href="https://www.facebook.com/"><img class="footer-icon-hidden" src="../../assets/images/facebook.svg" /></a><br>
                <a href="https://www.instagram.com/"><img class="footer-icon-hidden" src="../../assets/images/instagram.svg" /></a><br>
                <a href="https://www.linkedin.com/company/software-decisions-inc."><img class="footer-icon-hidden" src="../../assets/images/linkedin.svg" /></a>
              </td>
              <td class="footer-header-navigate-column">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/aboutUs">about us</a>
                  </li>
                </ul>
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a class="nav-link" href="#">our services</a>
                  </li>
                </ul>
              </td>
              <td>
                phone: (713) 523-9911               
                <ul class="navbar-nav">
                  <li class="nav-item">
                    email: <a class="nav-email-link" href="mailto:sdi@sditx.com">sdi@sditx.com</a>
                  </li>
                </ul>
                <br>
                <br>
              </td>
              <td>
                7207 Regency Square Blvd, Ste. 150<br>
                Houston, TX 77036<br>
                <br>
                <br>
              </td>
            </tr>
            <tr>
              <td colspan="4" class="footer-copyright">
                Copyright 2020. Software Decision, Inc. All Rights Reserved
              </td>
            </tr>
          </table>
        </td>
        <!-- <td class="footer-box-two">
          <sdi-map></sdi-map>
        </td> -->
      </tr>
      </table>
  </div>
</nav>
