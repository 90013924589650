import { Component, OnInit } from "@angular/core";
import {
	trigger,
	transition,
	state,
	style,
	animate,
	animateChild,
	query,
	group
} from "@angular/animations";

@Component({
	selector: "sdi-infographic",
	templateUrl: "./infographic.component.html",
	styleUrls: ["./infographic.component.scss"],
	animations: [
		trigger("wheelTransition", [
			state(
				"home",
				style({
					transform: "translate(0, 0) scale(1, 1)"
				})
			),
			state(
				"rpaSelected",
				style({
					transform:
						"translate(-950px, 0) scale(1.75, 1.75) rotate(90deg)"
				})
			),
			state(
				"webDevSelected",
				style({
					transform:
						"translate(-950px, 0) scale(1.75, 1.75) rotate(180deg)"
				})
			),
			state(
				"appDevSelected",
				style({
					transform: "translate(-950px, 0) scale(1.75, 1.75)"
				})
			),
			state(
				"consultingSelected",
				style({
					transform:
						"translate(-950px, 0) scale(1.75, 1.75) rotate(-90deg)"
				})
			),
			transition("* => *", [
				group([
					query("@rpaCircleSelect", animateChild()),
					query("@rpaDescSelect", animateChild()),
					query("@webDevCircleSelect", animateChild()),
					query("@webDevDescSelect", animateChild()),
					query("@appDevCircleSelect", animateChild()),
					query("@appDevDescSelect", animateChild()),
					query("@consultingCircleSelect", animateChild()),
					query("@consultingDescSelect", animateChild()),
					animate(600)
				])
			])
		]),
		trigger("rpaCircleSelect", [
			state(
				"home",
				style({
					opacity: 1.0
				})
			),
			state(
				"rpsSelected",
				style({
					opacity: 1.0,
					backgroundColor: "#008acc"
				})
			),
			state(
				"webDevSelected, appDevSelected, consultingSelected",
				style({
					opacity: 0.0,
					backgroundColor: "#034F8A"
				})
			),
			transition("* => *", animate(600))
		]),
		trigger("rpaDescSelect", [
			state(
				"rpaSelected",
				style({
					opacity: 1.0
				})
			),
			state(
				"home, webDevSelected, appDevSelected, consultingSelected",
				style({
					opacity: 0.0
				})
			),
			transition("* => *", animate(600))
		]),
		trigger("webDevCircleSelect", [
			state(
				"home",
				style({
					opacity: 1.0
				})
			),
			state(
				"webDevSelected",
				style({
					opacity: 1.0,
					backgroundColor: "#008acc"
				})
			),
			state(
				"rpaSelected, appDevSelected, consultingSelected",
				style({
					opacity: 0.0,
					backgroundColor: "#034F8A"
				})
			),
			transition("* => *", animate(600))
		]),
		trigger("webDevDescSelect", [
			state(
				"webDevSelected",
				style({
					opacity: 1.0
				})
			),
			state(
				"home, rpaSelected, appDevSelected, consultingSelected",
				style({
					opacity: 0.0
				})
			),
			transition("* => *", animate(600))
		]),
		trigger("appDevCircleSelect", [
			state(
				"home",
				style({
					opacity: 1.0
				})
			),
			state(
				"appDevSelected",
				style({
					opacity: 1.0,
					backgroundColor: "#008acc"
				})
			),
			state(
				"webDevSelected, rpaSelected, consultingSelected",
				style({
					opacity: 0.0,
					backgroundColor: "#034F8A"
				})
			),
			transition("* => *", animate(600))
		]),
		trigger("appDevDescSelect", [
			state(
				"appDevSelected",
				style({
					opacity: 1.0
				})
			),
			state(
				"home, webDevSelected, rpaSelected, consultingSelected",
				style({
					opacity: 0.0
				})
			),
			transition("* => *", animate(600))
		]),
		trigger("consultingCircleSelect", [
			state(
				"home",
				style({
					opacity: 1.0
				})
			),
			state(
				"consultingSelected",
				style({
					opacity: 1.0,
					backgroundColor: "#008acc"
				})
			),
			state(
				"webDevSelected, appDevSelected, rpaSelected",
				style({
					opacity: 0.0,
					backgroundColor: "#034F8A"
				})
			),
			transition("* => *", animate(600))
		]),
		trigger("consultingDescSelect", [
			state(
				"consultingSelected",
				style({
					opacity: 1.0
				})
			),
			state(
				"home, webDevSelected, appDevSelected, rpaSelected",
				style({
					opacity: 0.0
				})
			),
			transition("* => *", animate(600))
		])
	]
})
export class InfographicComponent {
	centerLabel = "Services";
	rpaInOutState = "begin";
	wheelState = "home";

	returnCenterLabelToDefault(): void {
		this.centerLabel = "Services";
	}

	onRPAClick(): void {
		if (this.wheelState == "rpaSelected") {
			this.wheelState = "home";
			this.centerLabel = "Services";
		} else {
			this.wheelState = "rpaSelected";
			this.centerLabel = "";
		}
	}

	onWebDevClick(): void {
		if (this.wheelState == "webDevSelected") {
			this.wheelState = "home";
			this.centerLabel = "Services";
		} else {
			this.wheelState = "webDevSelected";
			this.centerLabel = "";
		} 
	}

	onAppDevClick(): void {
		if (this.wheelState == "appDevSelected") {
			this.wheelState = "home";
			this.centerLabel = "Services";
		} else {
			this.wheelState = "appDevSelected";
			this.centerLabel = "";
		} 
	}

	onConsultingClick(): void {
		if (this.wheelState == "consultingSelected") {
			this.wheelState = "home";
			this.centerLabel = "Services";
		} else {
			this.wheelState = "consultingSelected";
			this.centerLabel = "";
		} 
	}

	onHomeClick(): void {
		this.wheelState = "home";
		this.centerLabel = "Services";
	}
}
