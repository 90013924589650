import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { HomeComponent } from "./home/home.component";
import { HomepageAboutUsComponent } from "./homepage-about-us/homepage-about-us.component";
import { NewsMediaComponent } from "./news-media/news-media.component";
import { NotFoundComponent } from "./not-found/not-found.component";

const routes: Routes = [
	{ path: "", pathMatch: "full", redirectTo: "home" },
	{ path: "home", component: HomeComponent },
	{ path: "contactUs", component: ContactUsComponent },
	{ path: "aboutUs", component: HomepageAboutUsComponent }, // To-Do: Specific AboutUs component, not HomepageAboutUsComponent
	{ path: "news-media", component: NewsMediaComponent }, // To-Do: Delete?
	{
		path: "sdiServices",
		loadChildren: () =>
			import("./sdi-services/sdi-services.module").then(
				(m) => m.SdiServicesModule
			)
	},
	{ path: "**", component: NotFoundComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
