<div id="contact-us">
	<div id="panel">
		<h1>Contact Us</h1>
	</div>
	<div class="container" style="margin: 5rem auto">
		<div class="row">
			<div class="col">
				<h3>get</h3>
				<h3>IN TOUCH</h3>
			</div>
			<div class="col">
				<h5>
					For more information, please contact us at
					<span style="color: #1eb6ff">sdi@sditx.com</span>
				</h5>
				<!-- <h5>Follow Us:</h5> -->
				<div id="social-icon-hidden" class="social-icon-hidden">
					<a href="https://www.facebook.com/"
						><img src="../../assets/images/facebook.svg"
					/></a>
					<a href="https://www.instagram.com/"
						><img src="../../assets/images/instagram.svg"
					/></a>
					<a
						href="https://www.linkedin.com/company/software-decisions-inc."
						><img src="../../assets/images/linkedin.svg"
					/></a> 
				</div>
			</div>
		</div>
	</div>
</div>
