import {
	animate,
	state,
	style,
	transition,
	trigger
} from "@angular/animations";
import { Component, HostListener, OnInit } from "@angular/core";

@Component({
	selector: "sdi-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
	animations: [
		trigger("headerState", [
			state(
				"hide",
				style({
					"background-color": "transparent"
				})
			),
			state(
				"show",
				style({
					"background-color": "#034F8A"
				})
			),
			transition("hide => show", animate("300ms ease-in")),
			transition("show => hide", animate("300ms ease-in"))
		]),
		trigger("servicesMenuState", [
			state(
				"hide",
				style({
					opacity: 0
				})
			),
			state(
				"show",
				style({
					opacity: 1
				})
			),
			transition("hide => show", animate("300ms ease-in")),
			transition("show => hide", animate("300ms ease-in"))
		])
	]
})
export class HeaderComponent implements OnInit {
	headerState = "hide";
	servicesMenuState = "hide";
	isScroll = false;
	isClicked = false;

	ngOnInit(): void {
		this.headerState = "hide";
		this.servicesMenuState = "hide";
	}

	onClick(): void {
		this.servicesMenuState === "hide"
			? (this.servicesMenuState = "show")
			: (this.servicesMenuState = "hide");
		this.isClicked = !this.isClicked;
		console.log(this.isClicked);
		if (this.headerState === "hide") {
			this.headerState = "show";
		} else if (this.headerState === "show" && window.pageYOffset < 100) {
			this.headerState = "hide";
		}
	}

	@HostListener("window:scroll", ["$event"])
	onScroll(): void {
		if (window.pageYOffset >= 100) {
			if (this.headerState === "hide") {
				this.headerState = "show";
			}
		} else {
			if (this.isClicked && this.headerState === "show") {
				this.headerState = "show";
			} else {
				this.headerState = "hide";
			}
		}
	}
}
