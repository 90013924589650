import { Component } from "@angular/core";

@Component({
	selector: "sdi-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent {
	title = "sditx-frontend";
}
