<div id="MissionStatement">
	<div class="MissionStatement">
		<h1>Mission Statement</h1>
	</div>
	<div class="container MissionStatement-Text">
		<div class="row">
			SDi provides premier automation and data management expertise that
			transforms complex challenges into intuitive, efficient and
			innovative software systems.
		</div>
	</div>
</div>
