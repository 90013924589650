import { Injectable } from "@angular/core";
import { Service } from "src/app/shared/service";

@Injectable({
	providedIn: "root"
})
export class ServiceContentService {
	private services: Service[] = [
		new Service(
			"Robotic Desktop Automation",
			"https://images.unsplash.com/photo-1560574188-6a6774965120?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
			[
				"When you want to elevate your employee productivity, focus more on your customer and return to your core business, then you require a robotic automation solution to handle mundane, repetitive and sometimes complex tasks. With 100s of SDi robots currently in service, our clients maximize ROI by automating monotonous human tasks. How can we transform your business?",
				"With 100s of SDi robots currently in service, our clients maximize ROI by automating monotonous human tasks. How can we transform your business? ",
				"Gravida arcu ac tortor dignissim convallis aenean et tortor at. Nulla at volutpat diam ut. Duis at tellus at urna condimentum. Arcu dictum varius duis at consectetur lorem donec massa sapien. Accumsan in nisl nisi scelerisque eu ultrices vitae auctor. Viverra accumsan in nisl nisi scelerisque eu ultrices vitae. Quam quisque id diam vel quam elementum pulvinar. Nisl nunc mi ipsum faucibus vitae. Viverra mauris in aliquam sem fringilla ut morbi. Sit amet massa vitae tortor condimentum lacinia quis vel eros. Eu sem integer vitae justo eget magna. Cursus in hac habitasse platea dictumst quisque sagittis purus sit. Fringilla est ullamcorper eget nulla. At auctor urna nunc id cursus metus aliquam eleifend mi. Eget sit amet tellus cras adipiscing enim eu turpis egestas. Pulvinar elementum integer enim neque volutpat ac. Quis enim lobortis scelerisque fermentum dui faucibus in ornare quam. Blandit massa enim nec dui nunc mattis. Semper auctor neque vitae tempus quam. In cursus turpis massa tincidunt dui.",
				"Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Morbi quis commodo odio aenean. Gravida dictum fusce ut placerat orci nulla. Lectus nulla at volutpat diam ut venenatis tellus in. Pretium viverra suspendisse potenti nullam ac tortor vitae purus. Tincidunt id aliquet risus feugiat in ante metus dictum at. Leo a diam sollicitudin tempor. Tempus urna et pharetra pharetra massa massa ultricies mi quis. Nibh praesent tristique magna sit amet purus. Magnis dis parturient montes nascetur ridiculus mus mauris vitae ultricies.",
				"Ut faucibus pulvinar elementum integer enim. Tempus egestas sed sed risus pretium quam vulputate dignissim. Sollicitudin ac orci phasellus egestas tellus. At elementum eu facilisis sed. Pulvinar elementum integer enim neque volutpat ac tincidunt vitae. Quam id leo in vitae turpis massa sed elementum. Sit amet justo donec enim diam vulputate ut pharetra. Mattis vulputate enim nulla aliquet porttitor. Nisl rhoncus mattis rhoncus urna neque viverra justo nec. Quisque non tellus orci ac. Egestas congue quisque egestas diam in arcu cursus euismod. Tellus rutrum tellus pellentesque eu tincidunt tortor. Tristique senectus et netus et malesuada fames ac turpis egestas. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. A lacus vestibulum sed arcu non odio euismod lacinia at. Elit duis tristique sollicitudin nibh. Vel orci porta non pulvinar neque laoreet. Proin nibh nisl condimentum id venenatis a condimentum vitae."
			]
		),
		new Service(
			"Web Development/Web Design",
			"https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1950&q=80",
			[
				"Whether you need a simple web page presence or a complex e-commerce portal, SDi brings your ideas to life by uniting creative design with efficient programming. Collaborating with SDi ensures your values and requirements result in an easy-to-follow website that looks great, performs well and realizes your vision of a seamless user experience.",
				"Ut faucibus pulvinar elementum integer enim. Tempus egestas sed sed risus pretium quam vulputate dignissim. Sollicitudin ac orci phasellus egestas tellus. At elementum eu facilisis sed. Pulvinar elementum integer enim neque volutpat ac tincidunt vitae. Quam id leo in vitae turpis massa sed elementum. Sit amet justo donec enim diam vulputate ut pharetra. Mattis vulputate enim nulla aliquet porttitor. Nisl rhoncus mattis rhoncus urna neque viverra justo nec. Quisque non tellus orci ac. Egestas congue quisque egestas diam in arcu cursus euismod. Tellus rutrum tellus pellentesque eu tincidunt tortor. Tristique senectus et netus et malesuada fames ac turpis egestas. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. A lacus vestibulum sed arcu non odio euismod lacinia at. Elit duis tristique sollicitudin nibh. Vel orci porta non pulvinar neque laoreet. Proin nibh nisl condimentum id venenatis a condimentum vitae.",
				"Sociis natoque penatibus et magnis dis parturient montes. Ut eu sem integer vitae. Fringilla urna porttitor rhoncus dolor purus non enim. Condimentum lacinia quis vel eros donec ac odio. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Cursus eget nunc scelerisque viverra mauris in aliquam. Cursus turpis massa tincidunt dui ut ornare lectus sit. Donec ac odio tempor orci dapibus ultrices. Vel facilisis volutpat est velit egestas dui id ornare arcu. Suscipit adipiscing bibendum est ultricies. Amet aliquam id diam maecenas ultricies. Non arcu risus quis varius quam quisque id diam vel. Justo donec enim diam vulputate ut. Suspendisse potenti nullam ac tortor vitae purus faucibus ornare suspendisse."
			]
		),
		new Service(
			"App Development/Backend Service",
			"https://cdn.pixabay.com/photo/2015/12/11/09/30/mobile-phone-1087845_960_720.jpg",
			[
				"When you partner with SDi to realize your product vision, you can be assured of success because custom application development is in our DNA. Whether you need to bridge multiple systems, interface into data or overhaul a business process, SDi simplifies and distills your complex requirements into user-friendly and reliable IT solutions.",
				"Orci dapibus ultrices in iaculis nunc sed augue lacus. Massa sed elementum tempus egestas sed sed risus pretium. Tortor at risus viverra adipiscing at in tellus. Morbi tristique senectus et netus et malesuada fames. Dignissim cras tincidunt lobortis feugiat vivamus at. Nisi porta lorem mollis aliquam. Amet dictum sit amet justo donec enim diam vulputate. Fermentum iaculis eu non diam phasellus vestibulum lorem sed risus. In ornare quam viverra orci. Quisque egestas diam in arcu cursus euismod quis viverra nibh. Condimentum vitae sapien pellentesque habitant morbi tristique senectus et netus. Volutpat consequat mauris nunc congue nisi. Elementum sagittis vitae et leo duis ut. Turpis egestas sed tempus urna et pharetra. Scelerisque felis imperdiet proin fermentum leo vel orci. A cras semper auctor neque vitae tempus quam pellentesque nec.",
				"Magnis dis parturient montes nascetur ridiculus mus mauris. Senectus et netus et malesuada fames ac turpis. Aliquet risus feugiat in ante metus dictum at. Feugiat scelerisque varius morbi enim nunc faucibus a pellentesque. Ut diam quam nulla porttitor massa id neque aliquam. Vulputate ut pharetra sit amet aliquam id diam maecenas ultricies. Vitae tortor condimentum lacinia quis. Vitae purus faucibus ornare suspendisse. In cursus turpis massa tincidunt dui ut ornare lectus. Facilisis magna etiam tempor orci. Id porta nibh venenatis cras sed felis. Ipsum consequat nisl vel pretium lectus quam id. Eu tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Non quam lacus suspendisse faucibus."
			]
		),
		new Service(
			"Consulting",
			"https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80",
			[
				"No matter the size of your business, today's competitive market requires embracing technology to maintain a qualitative edge. That's why partnering with SDi consultants on strategic decisions and business-critical IT solutions culminates in a successful implementation. Team up with SDi to turn IT into a major contributor of your business' success.",
				"Quis commodo odio aenean sed adipiscing diam. Nulla porttitor massa id neque aliquam vestibulum. Diam donec adipiscing tristique risus nec feugiat. Commodo ullamcorper a lacus vestibulum sed. Id eu nisl nunc mi. Massa tincidunt dui ut ornare lectus sit amet est. Duis ut diam quam nulla. Vitae proin sagittis nisl rhoncus. Diam sollicitudin tempor id eu nisl. Purus faucibus ornare suspendisse sed. Sed arcu non odio euismod lacinia at. Viverra vitae congue eu consequat ac felis. Facilisis leo vel fringilla est ullamcorper eget nulla facilisi etiam. Quisque id diam vel quam elementum pulvinar etiam non quam. Felis imperdiet proin fermentum leo vel.",
				"Et netus et malesuada fames ac turpis egestas maecenas. Diam maecenas ultricies mi eget mauris pharetra et ultrices neque. Pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Nibh nisl condimentum id venenatis a condimentum. Ut porttitor leo a diam sollicitudin. Tempus urna et pharetra pharetra massa massa ultricies mi quis. Commodo nulla facilisi nullam vehicula ipsum a arcu. Hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper. In massa tempor nec feugiat. Lobortis feugiat vivamus at augue eget. Lobortis scelerisque fermentum dui faucibus. Vitae tortor condimentum lacinia quis vel eros donec ac. Morbi tristique senectus et netus et malesuada fames ac turpis. Pulvinar pellentesque habitant morbi tristique. Et magnis dis parturient montes nascetur ridiculus mus mauris. Maecenas ultricies mi eget mauris pharetra et ultrices neque ornare. Nam libero justo laoreet sit."
			]
		)
	];

	getServices(): Service[] {
		return this.services;
	}
}
