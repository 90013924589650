import { AfterViewInit, Component } from "@angular/core";
import {
	trigger,
	transition,
	state,
	style,
	animate
} from "@angular/animations";

@Component({
	selector: "sdi-carousel",
	templateUrl: "./carousel.component.html",
	styleUrls: ["./carousel.component.scss"],
	animations: [
		// animation triggers go here
		trigger("divState", [
			state(
				"begin",
				style({
					"background-position": "0 0, 0 100%, center"
				})
			),
			state(
				"end",
				style({
					"background-position": "-4000px 0, 4000px 100%, center"
				})
			),
			transition("begin => end", animate(5000)),
			transition("end => begin", animate(5000))
		])
	]
})
export class CarouselComponent implements AfterViewInit {
	state = "begin";

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.state = "end";
		});
	}
}
