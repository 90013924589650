import { Injectable } from "@angular/core";

@Injectable()
export class MapService {
	getMapImage(): string {
		return "../../assets/images/maps/SDITXMap_Medium.png";
	}

	getMapAPI(): null {
		return null;
	}
}
