<section id="newsMedia">
	<div class="container">
		<div class="row">
			<h1>News & Media</h1>
		</div>
		<div class="row">
			<h3>Stay tuned for exciting SDi news!</h3>
		</div>
		<div class="row">
			<h3><a class="go-home" routerLink="/home">Go Home</a></h3>
		</div>
	</div>
</section>
