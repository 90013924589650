<div class="container-fluid full-width">
	<sdi-carousel></sdi-carousel>
	<div class="row">
		<div class="d-none d-xl-block col"></div>
		<div class="d-none d-xl-block col align-self-center">
			<sdi-infographic></sdi-infographic>
		</div>
		<div class="d-xl-none col align-self-center">
			<sdi-infographic-small></sdi-infographic-small>
		</div>
		<div class="d-none d-xl-block col"></div>
	</div>
	<sdi-homepage-about-us></sdi-homepage-about-us>
	<sdi-homepage-contact-us></sdi-homepage-contact-us>
	<div id="mapContent">
		<div>
			<h2>Our Office</h2>
			<p>7207 Regency Square Blvd, Ste. 150</p>
			<p>Houston, TX 77036</p>
		</div>
		<sdi-map></sdi-map>
	</div>
</div>
