import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { InfographicComponent } from "./infographic/infographic.component";
import { HeaderComponent } from "./header/header.component";
import { MissionStatementComponent } from "./mission-statement/mission-statement.component";
import { FooterComponent } from "./footer/footer.component";
import { HomepageContactUsComponent } from "./homepage-contact-us/homepage-contact-us.component";
import { HomepageAboutUsComponent } from "./homepage-about-us/homepage-about-us.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ServiceContentService } from "./sdi-services/services-components/service-content/service-content.service";
import { InfographicSmallComponent } from "./infographic-small/infographic-small.component";
import { MapComponent } from "./map/map.component";
import { MapService } from "./map/map.service";
import { NotFoundComponent } from "./not-found/not-found.component";
import { HomeComponent } from "./home/home.component";
import { NewsMediaComponent } from "./news-media/news-media.component";

@NgModule({
	declarations: [
		AppComponent,
		CarouselComponent,
		InfographicComponent,
		HeaderComponent,
		MissionStatementComponent,
		FooterComponent,
		HomepageContactUsComponent,
		HomepageAboutUsComponent,
		ContactUsComponent,
		InfographicSmallComponent,
		MapComponent,
		NotFoundComponent,
		HomeComponent,
		NewsMediaComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule
	],
	providers: [ServiceContentService, MapService],
	bootstrap: [AppComponent]
})
export class AppModule {}
