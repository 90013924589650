import { Component, OnInit } from "@angular/core";

import {
	trigger,
	transition,
	state,
	style,
	animate
} from "@angular/animations";

@Component({
	selector: "sdi-infographic-small",
	templateUrl: "./infographic-small.component.html",
	styleUrls: ["./infographic-small.component.scss"],
	animations: [
		trigger("rpaSelect", [
			state("open", style({ "max-height": "400px" })),
			state("closed", style({ "max-height": "0px" })),
			transition("open <=> closed", animate("400ms ease-in"))
		]),
		trigger("webDevSelect", [
			state("open", style({ "max-height": "400px" })),
			state("closed", style({ "max-height": "0px" })),
			transition("open <=> closed", animate("400ms ease-in"))
		]),
		trigger("appDevSelect", [
			state("open", style({ "max-height": "400px" })),
			state("closed", style({ "max-height": "0px" })),
			transition("open <=> closed", animate("400ms ease-in"))
		]),
		trigger("consultingSelect", [
			state("open", style({ "max-height": "400px" })),
			state("closed", style({ "max-height": "0px" })),
			transition("open <=> closed", animate("400ms ease-in"))
		])
	]
})
export class InfographicSmallComponent {
	rpaSelectState = "closed";
	webDevSelectState = "closed";
	appDevSelectState = "closed";
	consultingSelectState = "closed";

	onRPAClick(): void {
		if (this.rpaSelectState == "closed") {
			this.rpaSelectState = "open";
		} else {
			this.rpaSelectState = "closed";
		}
	}

	onWebDevClick(): void {
		if (this.webDevSelectState == "closed") {
			this.webDevSelectState = "open";
		} else {
			this.webDevSelectState = "closed";
		}
	}

	onAppDevClick(): void {
		if (this.appDevSelectState == "closed") {
			this.appDevSelectState = "open";
		} else {
			this.appDevSelectState = "closed";
		}
	}

	onConsultingClick(): void {
		if (this.consultingSelectState == "closed") {
			this.consultingSelectState = "open";
		} else {
			this.consultingSelectState = "closed";
		}
	}
}
