import { Component, OnInit } from "@angular/core";
import { MapService } from "./map.service";

@Component({
	selector: "sdi-map",
	templateUrl: "./map.component.html",
	styleUrls: ["./map.component.scss"]
})
export class MapComponent implements OnInit {
	mapHasAPI = false;
	mapObj = null;
	mapImageLink = "";
	constructor(private mapService: MapService) {}

	ngOnInit(): void {
		setTimeout(() => {
			this.mapObj = this.mapService.getMapAPI();
			if (this.mapObj === null) {
				this.mapHasAPI = false;
				this.mapImageLink = this.mapService.getMapImage();
			}
		});
	}
}
