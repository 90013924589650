<div class="wrapper">
	<div class="serviceCircle">Services</div>
	<div class="spoke"></div>
	<div class="outercircle" (click)="onRPAClick()">
		<div class="circle">
			<span> Robotic Desktop Automation </span>
		</div>
	</div>
	<p class="serviceText" [@rpaSelect]="rpaSelectState">
		<br />
		When you want to elevate your employee productivity, focus more on your
		customer and return to your core business, then you require a robotic
		automation solution to handle mundane, repetitive and sometimes complex
		tasks. With 100s of SDi robots currently in service, our clients
		maximize ROI by automating monotonous human tasks. How can we transform
		your business?
		<br /><br />
	</p>
	<div class="spoke"></div>
	<div class="outercircle" (click)="onWebDevClick()">
		<div class="circle">
			<span>
				Web Development
				<hr />
				Web<br />Design
			</span>
		</div>
	</div>
	<p class="serviceText" [@webDevSelect]="webDevSelectState">
		<br />
		Whether you need a simple web page presence or a complex e-commerce
		portal, SDi brings your ideas to life by uniting creative design with
		efficient programming. Collaborating with SDi ensures your values and
		requirements result in an easy-to-follow website that looks great,
		performs well and realizes your vision of a seamless user experience.
		<br /><br />
	</p>
	<div class="spoke"></div>
	<div class="outercircle" (click)="onAppDevClick()">
		<div class="circle">
			<span>
				Application Development
				<hr />
				Backend<br />Services
			</span>
		</div>
	</div>
	<p class="serviceText" [@appDevSelect]="appDevSelectState">
		<br />
		When you partner with SDi to realize your product vision, you can be
		assured of success because custom application development is in our DNA.
		Whether you need to bridge multiple systems, interface into data or
		overhaul a business process, SDi simplifies and distills your complex
		requirements into user-friendly and reliable IT solutions.
		<br /><br />
	</p>
	<div class="spoke"></div>
	<div class="outercircle" (click)="onConsultingClick()">
		<div class="circle">
			<span> Consulting </span>
		</div>
	</div>
	<p class="serviceText" [@consultingSelect]="consultingSelectState">
		<br />
		No matter the size of your business, today's competitive market requires
		embracing technology to maintain a qualitative edge. That's why
		partnering with SDi consultants on strategic decisions and
		business-critical IT solutions culminates in a successful
		implementation. Team up with SDi to turn IT into a major contributor of
		your business' success.
		<br /><br />
	</p>
</div>
