<div id="title">
	<div id="panel">
		<h1>Contact Us</h1>
	</div>
	<div id="panel2"></div>
</div>

<div id="contactInfo">
	<div class="container">
		<h1 id="letusknow">Let us know how we can help!</h1>

		<div class="content">
			<h2>Contact Us</h2>
			<div>
				<p>Email: <a href="mailto: sdi@sditx.com">sdi@sditx.com</a></p>
				<p>Phone: 713-523-9911</p>
			</div>
		</div>

		<div class="content">
			<div>
				<h2>Our Office</h2>
				<p>7207 Regency Square Blvd, Ste. 150</p>
				<p>Houston, TX 77036</p>
			</div>
			<sdi-map></sdi-map>
		</div>
	</div>
</div>
