<nav
	class="navbar navbar-expand-lg navbar-dark fixed-top"
	id="bg-blue"
	[@headerState]="headerState"
>
	<div class="header">
		<a class="navbar-brand" routerLink="/home" id="logo"
			><img
				class="headerLogo"
				src="../../assets/images/logos/SDiOrb_newColors_50p.svg"
		/></a>

		<div class="collapse navbar-collapse" id="menuButtons">
			<ul class="navbar-nav">
				<h3
					type="button"
					data-toggle="collapse"
					data-target="#menuButtons"
					id="closeBtn"
				>
					x
				</h3>
				<li class="nav-item">
					<a class="nav-link" routerLink="/aboutUs">about us</a>
				</li>
				<li
					class="nav-item"
					data-toggle="collapse"
					data-target="#service-dropdown"
					aria-expanded="false"
					aria-controls="service-dropdown"
				>
					<a class="nav-link sdi-svc-cursor" (click)="onClick()"
						>services</a
					>
					<div
						class="collapse"
						id="service-dropdown"
						[@servicesMenuState]="servicesMenuState"
					>
						<div id="service-content">
							<ul class="navbar-nav">
								<li class="nav-item">
									<a
										class="nav-link"
										routerLink="/sdiServices/roboticDesktopAutomation"
									>
										<img
											src="../../assets/images/White_Circle.svg"
										/>
										Robotic Desktop Automation
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										routerLink="/sdiServices/webDevelopment"
									>
										<div class="container">
											<img
												src="../../assets/images/White_Circle.svg"
											/>
											<div>
												<p
													style="
														border-bottom: solid
															white;
														margin-bottom: auto;
													"
												>
													Web Design
												</p>
												<p>Web Development</p>
											</div>
										</div>
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										routerLink="/sdiServices/backendServices"
									>
										<div class="container">
											<img
												src="../../assets/images/White_Circle.svg"
											/>
											<div>
												<p
													style="
														border-bottom: solid
															white;
														margin-bottom: auto;
													"
												>
													Application Development
												</p>
												<p>Backend Services</p>
											</div>
										</div>
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										routerLink="/sdiServices/consulting"
									>
										<img
											src="../../assets/images/White_Circle.svg"
										/>Consulting</a
									>
								</li>
							</ul>
						</div>
					</div>
				</li>
				<li class="nav-item">
					<a class="nav-link" routerLink="/news-media">news/media</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" routerLink="/contactUs">contact us</a>
				</li>
			</ul>
			<div class="social-icon-hidden">
				<a href="https://www.facebook.com/sditx/"
					><img src="../../assets/images/facebook.svg"
				/></a>
				<a href="https://www.instagram.com/"
					><img src="../../assets/images/instagram.svg"
				/></a>
				<a
					href="https://www.linkedin.com/company/software-decisions-inc."
					><img src="../../assets/images/linkedin.svg"
				/></a>
			</div>
		</div>
	</div>
	<button
		class="navbar-toggler"
		type="button"
		data-toggle="collapse"
		data-target="#menuButtons"
		aria-controls="menuButtons"
		aria-expanded="false"
		aria-label="Toggle navigation"
	>
		<span class="navbar-toggler-icon navbar-light"></span>
	</button>
</nav>
