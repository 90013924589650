export class Service {
	name = "";
	imageSrc = "";
	textParagraphs: string[] = [];

	constructor(name: string, imageSrc: string, textParagraphs: string[]) {
		this.imageSrc = imageSrc;
		this.name = name;
		this.textParagraphs = textParagraphs;
	}
}
