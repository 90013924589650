<div id="homepage-about-us">
	<div id="panel">
		<h1>About Us</h1>
	</div>
	<div class="container">
		<div class="row homepage-about-us-text">
			Software Decisions, Inc. provides software consulting and
			development services including but not limited to analyzing the
			Client’s automation needs for data retrieval and processing,
			identifying implementation objectives, scoping changes, designing &
			implementing technical solutions, deploying software solutions and
			supporting the developed systems.
		</div>
	</div>
</div>
