<div class="infographic">
	<div [@wheelTransition]="wheelState" class="wheel">
		<div id="backgroundcircle1"></div>
		<div id="backgroundcircle2"></div>
		<div id="div1">
      
			<div
				[@rpaCircleSelect]="wheelState"
				id="circle1"
				class="circle"
				(click)="onRPAClick()"
			>
				<div class="spoke"></div>
				<div class="outercircle"></div>
				<div *ngIf="wheelState == 'home'">
					Robotic Desktop Automation
				</div>
				<div *ngIf="wheelState == 'rpaSelected'">
					<i class="fas fa-angle-double-down fa-5x backarrow"></i>
				</div>
			</div>
      <div *ngIf="wheelState == 'rpaSelected'" class="rotateArrow rotateUp"
        (click)="onWebDevClick()">
        <i class="fas fa-angle-double-down backarrow"></i>
      </div>
      <div *ngIf="wheelState == 'rpaSelected'" class="rotateArrow rotateDown"
        (click)="onAppDevClick()">
        <i class="fas fa-angle-double-down backarrow"></i>
      </div>
			<div [@rpaDescSelect]="wheelState" class="discriptionText">
				<h3>Robotic Desktop Automation</h3>
				<p>
					When you want to elevate your employee productivity, focus
					more on your customer and return to your core business, then
					you require a robotic automation solution to handle mundane,
					repetitive and sometimes complex tasks. With 100s of SDi
					robots currently in service, our clients maximize ROI by
					automating monotonous human tasks. How can we transform your
					business?
				</p>
			</div>
		</div>
		<div id="div2">
			<div
				[@webDevCircleSelect]="wheelState"
				id="circle2"
				class="circle"
				(click)="onWebDevClick()"
			>
				<div class="spoke"></div>
				<div class="outercircle"></div>
				<div *ngIf="wheelState == 'home'">
					<!-- <i class="fas fa-arrow-left"></i> -->
					Web Development<br />
					<hr />
					Web Design
				</div>
				<div *ngIf="wheelState == 'webDevSelected'">
					<i class="fas fa-angle-double-right fa-5x backarrow"></i>
				</div>
			</div>
      <div *ngIf="wheelState == 'webDevSelected'" class="rotateArrow rotateUp"
        (click)="onConsultingClick()">
        <i class="fas fa-angle-double-down backarrow"></i>
      </div>
      <div *ngIf="wheelState == 'webDevSelected'" class="rotateArrow rotateDown"
        (click)="onRPAClick()">
        <i class="fas fa-angle-double-down backarrow"></i>
      </div>
			<div [@webDevDescSelect]="wheelState" class="discriptionText">
				<h3>Web Development | Web Design</h3>
				<p>
					Whether you need a simple web page presence or a complex
					e-commerce portal, SDi brings your ideas to life by uniting
					creative design with efficient programming. Collaborating
					with SDi ensures your values and requirements result in an
					easy-to-follow website that looks great, performs well and
					realizes your vision of a seamless user experience.
				</p>
			</div>
		</div>
		<div id="div3">
			<div
				[@appDevCircleSelect]="wheelState"
				id="circle3"
				class="circle"
				(click)="onAppDevClick()"
			>
				<div class="spoke"></div>
				<div class="outercircle"></div>
				<div *ngIf="wheelState == 'home'">
					Application Development
					<hr />
					Backend Services
				</div>
				<div *ngIf="wheelState == 'appDevSelected'">
					<i class="fas fa-angle-double-left fa-5x backarrow"></i>
				</div>
			</div>
      <div *ngIf="wheelState == 'appDevSelected'" class="rotateArrow rotateUp"
        (click)="onRPAClick()">
        <i class="fas fa-angle-double-down backarrow"></i>
      </div>
      <div *ngIf="wheelState == 'appDevSelected'" class="rotateArrow rotateDown"
        (click)="onConsultingClick()">
        <i class="fas fa-angle-double-down backarrow"></i>
      </div>
			<div [@appDevDescSelect]="wheelState" class="discriptionText">
				<h3>Application Development | Backend Services</h3>
				<p>
					When you partner with SDi to realize your product vision,
					you can be assured of success because custom application
					development is in our DNA. Whether you need to bridge
					multiple systems, interface into data or overhaul a business
					process, SDi simplifies and distills your complex
					requirements into user-friendly and reliable IT solutions.
				</p>
			</div>
		</div>
		<div id="div4">
			<div
				[@consultingCircleSelect]="wheelState"
				id="circle4"
				class="circle"
				(click)="onConsultingClick()"
			>
				<div class="spoke"></div>
				<div class="outercircle"></div>
				<div *ngIf="wheelState == 'home'">Consulting</div>
				<div *ngIf="wheelState == 'consultingSelected'">
					<i class="fas fa-angle-double-up fa-5x backarrow"></i>
				</div>
			</div>
      <div *ngIf="wheelState == 'consultingSelected'" class="rotateArrow rotateUp"
        (click)="onAppDevClick()">
        <i class="fas fa-angle-double-down backarrow"></i>
      </div>
      <div *ngIf="wheelState == 'consultingSelected'" class="rotateArrow rotateDown"
        (click)="onWebDevClick()">
        <i class="fas fa-angle-double-down backarrow"></i>
      </div>
			<div [@consultingDescSelect]="wheelState" class="discriptionText">
				<h3>Consulting</h3>
				<p>
					No matter the size of your business, today's competitive
					market requires embracing technology to maintain a
					qualitative edge. That's why partnering with SDi consultants
					on strategic decisions and business-critical IT solutions
					culminates in a successful implementation. Team up with SDi
					to turn IT into a major contributor of your business'
					success.
				</p>
			</div>
		</div>
		<div (click)="onHomeClick()" id="centercircle">{{ centerLabel }}</div>
	</div>
</div>
